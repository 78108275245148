import MeetTheDirectorsImage from "../../assets/our-offering.jpg";
import TanjaFauxImage from "../../assets/Tanja-Faux.jpg";
import MarkJurgensImage from "../../assets/Mark-Jurgens.jpg";
import FedericoTozziImage from "../../assets/Federico-Tozzi.jpg";

import { StyledHeading, EmailButton, DirectorCard } from "../../components";
import {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  DirectorCardContainer,
} from "./meet-the-directors.styles";

const MeetTheDirectors = () => {
  return (
    <Container id="MeetTheDirectors">
      <Heading image={MeetTheDirectorsImage}>
        <HeadingContent>
          <StyledHeading light>Meet the Directors</StyledHeading>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          <DirectorCardContainer>
            <DirectorCard
              image={TanjaFauxImage}
              heading="Tanja Faux"
              content="Tanja is the Founder and CEO at The Talent Experts.<br /><br />She has over
              23 years’ experience in recruitment in South Africa and holds an
              MBA (Cum Laude) from Stellenbosch University. She started her
              recruitment career with DAV Professional Placement Group (South
              Africa’s largest permanent recruitment company in South Africa at
              the time which formed part of the Adcorp Group) whom she was with
              for 15 years and where she worked her way up to Managing Director.<br /><br />
              After 15 years with DAV she took time out to do her MBA at
              Stellenbosch University. After this she did a one and a half years
              stint with UK listed recruitment company Gattaca (Networkers in
              South Africa) to turn the business around. After she had
              successfully done this she decided that what South Africa really
              needed was a niche, specialist recruitment company that focuses on
              quality recruitment and The Talent Experts was born. <br /><br />
              Tanja has a
              real passion for quality recruitment and for offering exceptional
              service to her clients and candidates. The company is based on a
              set of strong values and hiring practices and Tanja and her team
              truly live The Talent Expert’s Brand Promise which is “Connecting
              quality talent to exceptional opportunities whilst building
              lasting relationships”"
            />

            <DirectorCard
              image={MarkJurgensImage}
              heading="Mark Jurgens"
              content="Mark is a Senior Executive with extensive experience in operations management, 
              acquisitions and integrations, IPO’s, strategic planning, investor and shareholder relations.<br /><br />
              Mark founded Paracon Holdings, an ICT staffing and solutions business, in 1995 and listed Paracon 
              on the JSE four years later. Dimension Data became a strategic shareholder in early 2000. 
              Mark was involved in many successful start-ups over the years, numerous local and international 
              acquisitions including a 35% stake in Indian-based Nihilent Technologies in 2006. 
              He acquired and sold two tech companies in the USA and de-listed Paracon from the 
              JSE in 2011, in a sale to Adcorp Holdings.<br /><br />
              
              Mark was responsible for the acquisition of Australian based Paxus from CSC in 
              2012 and led the acquisition and de-listing of the Kelly Group SA from the JSE. 
              This resulted in the creation of TalentCRU, growing it into the largest MSP/RPO 
              business in South Africa. Mark was interim CEO of the Adcorp group and then took 
              the reins as COO of the group where he set in motion the turnaround strategy which 
              included the sale of Nihilent to Dimension Data PLC in 2017. Mark left Adcorp after 
              the successful turnaround in March 2019 to focus on investments. <br /><br />
              
              In 2023, Carambrooke invested in The Talent Experts with the aim of looking to 
              partner and grow the business by expanding the offering into ICT and contracting 
              services globally with the establishment of The Talent Experts Consulting. “With Tanja 
              guiding the business as an accomplished leader in the recruitment space and 
              establishing partnerships with leading players I am certain that we will 
              see exponential growth in The Talent Expert group of companies.”"
            />

            <DirectorCard
              image={FedericoTozziImage}
              heading="Federico Tozzi"
              content="Federico graduated from Wits University with a B.Comm Honours in Information 
              Systems and Corporate Finance, and was recruited from university by Dimension Data. 
              Shortly after working within the Oracle Division of Dimension Data (then called Oracle SPL), 
              he joined Paracon as a contractor.<br /><br />
              
              During his time at Paracon, Federico was responsible for the design and implementation 
              of Sun International’s Data Warehouse, which was used by gaming marketing to segment 
              their punters and mine the data of their MVGs (Most Valuable Guests). He also spent 
              several years working on projects at Discovery Holdings designing Data Marts and MIS 
              solutions, initially for the Health business in South Africa, then got involved with 
              projects for Discovery Vitality and Discovery Credit Card, and thereafter Destiny 
              in the USA, and prudential in the UK.<br /><br />
              
              Having worked in large corporates and having identified how large organizations 
              struggle to engage or simply communicate effectively with employees, Federico 
              stopped contracting with Paracon in 2007 and founded Corporate LAN Advertising in 
              April 2007, which aimed at solving the Employee Engagement problem using technology. 
              Over the years, Corporate LAN Advertising (CLA) has evolved into an multi-modular 
              Internal Communications platform, and has been adopted by many leading blue-chip 
              companies, across all sectors of the market, both private and public, locally and abroad."
            />
          </DirectorCardContainer>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default MeetTheDirectors;
