import {
  APSOLogoContainer,
  CloseIconContainer,
  Container,
  LogoContainer,
  MenuContainer,
  MenuEntry,
  PhoneEntry,
  RoutedMenuEntry,
  StyledLink,
} from "./menu.styles";

import APSOLogo from "../../assets/apso-logo.png";
import CloseIcon from "../../assets/close.svg";
import Logo from "../../assets/The_Talent_Experts.svg";
import { useHistory } from "react-router-dom";

const Menu = ({ setPage, setMobileMenuStatus, mobileMenuStatus }) => {
  const history = useHistory();

  const date = new Date();

  const handleClick = (page) => {
    setMobileMenuStatus(false);
    history.push("/");
    setPage(page);
  };

  return (
    <Container>
      {mobileMenuStatus && (
        <CloseIconContainer>
          <img
            src={CloseIcon}
            alt="Close"
            onClick={() => setMobileMenuStatus(false)}
          />
        </CloseIconContainer>
      )}
      <MenuContainer>
        <MenuEntry onClick={() => handleClick("Home")}>HOME</MenuEntry>
        <MenuEntry onClick={() => handleClick("AboutUs")}>ABOUT US</MenuEntry>
        <MenuEntry onClick={() => handleClick("OurOffering")}>
          OUR OFFERING
        </MenuEntry>
        <MenuEntry onClick={() => handleClick("OurProcessAndAgreement")}>
          OUR PROCESS &amp; AGREEMENT
        </MenuEntry>

        <MenuEntry>
          <RoutedMenuEntry
            to="/jobs"
            onClick={() => setMobileMenuStatus(false)}>
            JOBS
          </RoutedMenuEntry>
        </MenuEntry>
        <MenuEntry onClick={() => handleClick("MeetTheDirectors")}>
          MEET THE DIRECTORS
        </MenuEntry>
        <MenuEntry onClick={() => handleClick("ContactUs")}>
          CONTACT US
        </MenuEntry>

        <PhoneEntry href="tel:+27210351433">+27 21 035 1433</PhoneEntry>
      </MenuContainer>
      <APSOLogoContainer>
        <img src={APSOLogo} alt="APSO" />
      </APSOLogoContainer>

      <LogoContainer>
        <img
          src={Logo}
          alt="The Talent Experts"
          onClick={() => handleClick("Home")}
        />
        <div>
          <StyledLink
            to="/privacy-policy"
            onClick={() => setMobileMenuStatus(false)}>
            Privacy Policy
          </StyledLink>{" "}
          &bull;{" "}
          <StyledLink
            to="/email-disclaimer"
            onClick={() => setMobileMenuStatus(false)}>
            Email Disclaimer
          </StyledLink>
        </div>
        <div>
          <StyledLink
            to="/applicant-consent"
            onClick={() => setMobileMenuStatus(false)}>
            Applicant Consent
          </StyledLink>
        </div>
        <p>
          <small>&copy; {date.getFullYear()} The Talent Experts</small>
        </p>
      </LogoContainer>
    </Container>
  );
};

export default Menu;
