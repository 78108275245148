import ContactUsImage from "../../assets/contact-us.jpg";
import { StyledHeading, LinkButton } from "../../components";

import {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  AddressWrapper,
  Address,
} from "./contact-us.styles";

const OurOffering = () => {
  return (
    <Container id="ContactUs">
      <Heading image={ContactUsImage}>
        <HeadingContent>
          <StyledHeading light>Contact Us</StyledHeading>
          <div>
            <LinkButton label="Check out our jobs" path="/jobs" />
          </div>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          <p>
            We are passionate about what we do. We live and breathe matching
            career ambitions with business requirements. Please get in touch so
            we can make it happen for you.
          </p>

          <div>
            <a href="mailto:info@thetalentexperts.co.za?subject=My%20CV&body=Hi%20The%20Talent%20Experts!%0A%0APlease%20find%20my%20CV%20attached%0A%0A----%0ADon%27t%20forget%20to%20attach%20your%20CV!">
              Submit your CV
            </a>
          </div>

          <div>
            <a href="mailto:info@thetalentexperts.co.za?subject=Job%20specification&body=Hi%20The%20Talent%20Experts!%0A%0APlease%20find%20attached%20a%20job%20specification%0A%0A-----%0ADon%27t%20forget%20to%20attach%20the%20specification%20and%20feel%20free%20to%20add%20any%20important%20details%20you%20require%20in%20the%20message">
              Submit your company requirements
            </a>
          </div>

          <div>
            <a href="mailto:info@thetalentexperts.co.za?subject=Support%20question&body=">
              Questions and support
            </a>
          </div>

          <AddressWrapper>
            <Address>
              <h4>Head Office</h4>
              <p>
                Brookside Office Park <br />
                11 Imam Haron Road <br />
                Claremont <br />
                Cape Town
              </p>
            </Address>

            <Address>
              <h4>Johannesburg Branch</h4>
              <p>
                4 Ave <br />
                Parkhurst <br />
                Johannesburg
              </p>
            </Address>
          </AddressWrapper>

          <div>
            Phone: <a href="tel:+27210351433">+27 21 035 1433</a> (for all
            branches)
          </div>
          <div>
            <a href="mailto:info@thetalentexperts.co.za">
              info@thetalentexperts.co.za
            </a>
          </div>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default OurOffering;
