import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// const Heading = styled.div`
//   min-height: 5vh;
//   background-color: var(--black);
// `;

const Heading = styled.div`
  height: 30vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 1rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--white);
  min-height: 70vh;

  @media (max-width: 768px) {
    min-height: 50vh;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  background-color: var(--yellow);
`;

const Image = styled.div`
  min-width: 50%;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  outline: 20px solid var(--yellow);
  border-radius: 10px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const Content = styled.div`
  padding: 2rem;
  color: var(--black);

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  Image,
  Content,
};
