import {
  Container,
  ContentContainer,
  ContentWrapper,
  Heading,
  HeadingContent,
} from "./job.styles";
import { EmailButton, StyledHeading, LinkButton } from "../../../components";
import { useEffect, useState } from "react";

import AboutUsImage from "../../../assets/about-us.jpg";
import { useParams } from "react-router-dom";

const Job = ({ setPageTitle }) => {
  const [job, setJob] = useState(null);
  const [mailTo, setMailTo] = useState(null);
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    const date = new Date();
    const url =
      `https://api.thetalentexperts.co.za/jobs/${id}?$limit=100&` +
      `expireAt[$gte]=${date.toISOString()}&$client[includes]=name` +
      `&$client[includes]=surname&$client[includes]=email&$client[includes]=contactNo`;
    fetch(url)
      .then((response) => response.json())
      .then((job) => {
        setJob(job);
        setPageTitle(`${job.title} - ${job.location}`);
        setMailTo(
          `mailto:${job.consultant.email}?subject=Application for ${job.title}&body=Remember%20to%20attach%20your%20current%20CV`
        );
      });
  }, [params, setPageTitle]);

  return (
    <Container id="Home">
      <Heading image={AboutUsImage}>
        <HeadingContent>
          <StyledHeading light>{job && job.title} </StyledHeading>
          {job && <LinkButton label="< Back" path="/jobs" />}
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          {!job && <p>Loading...</p>}
          {job && (
            <>
              <div dangerouslySetInnerHTML={{ __html: job.advert }} />
              <EmailButton label="Apply Now" mailto={mailTo} />
              <div>&nbsp;</div>
            </>
          )}
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default Job;
