import {
  Container,
  ContentContainer,
  ContentWrapper,
  DivisionItem,
  DivisionsContainer,
  DivisionsMobileContainer,
  Heading,
  HeadingContent,
  JobCard,
  JobFooter,
  JobLink,
  JobLinkContainer,
  JobLocation,
  JobSalary,
  JobsContainer,
  JobTitle,
  SummaryTable,
} from "./jobs.styles";
import { useEffect, useState } from "react";

import AboutUsImage from "../../assets/about-us.jpg";
import { StyledHeading } from "../../components";
import { useHistory } from "react-router-dom";
import slugify from "slugify";

const Jobs = ({ setPageTitle }) => {
  const [jobs, setJobs] = useState(null);
  const [originalJobs, setOriginalJobs] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const [selectedDivisionId, setSelectedDivisionId] = useState(
    parseInt(localStorage.getItem("selectedDivisionId") || 0)
  );
  const history = useHistory();

  const summary = new URLSearchParams(window.location.search).get("summary");
  const API_URL = "https://api.thetalentexperts.co.za";
  // const API_URL = "http://localhost:3030";

  useEffect(() => {
    const date = new Date();
    const url = `${API_URL}/jobs?$limit=100&expireAt[$gte]=${date.toISOString()}`;
    fetch(url)
      .then((response) => response.json())
      .then(({ data }) => {
        setPageTitle("Jobs");
        const sorted = data.sort((a, b) => b.id - a.id);

        const divisions = new Map();
        for (let job of sorted) {
          if (job.division && job.division.name) {
            divisions.set(job.division.id, job.division);
          }
        }

        setDivisions([{ id: 0, name: "All" }, ...divisions.values()]);
        setOriginalJobs(sorted);
      });
  }, [
    setPageTitle,
    setJobs,
    setDivisions,
    setOriginalJobs,
    setSelectedDivisionId,
  ]);

  useEffect(() => {
    if (!originalJobs) return;
    const parsedSelectedDivisionId = parseInt(selectedDivisionId);
    const jobs =
      parsedSelectedDivisionId === 0
        ? originalJobs
        : originalJobs.filter(
            (job) => job.divisionId === parsedSelectedDivisionId
          );
    setJobs(jobs);
  }, [originalJobs, selectedDivisionId]);

  const navigate = (jobId) => {
    const job = jobs.find((job) => job.id === jobId);

    if (job) {
      const slugSettings = {
        replacement: "-",
        lower: true,
        strict: true,
        locale: "en",
        trim: true,
      };
      const slug = slugify(`${job.title} ${job.location}`, slugSettings);
      history.push(`/jobs/${slug}/${jobId}`);
    } else {
      history.push(`/jobs/${jobId}`);
    }
  };

  const handleDivisionChange = (divisionId) => {
    localStorage.setItem("selectedDivisionId", divisionId);
    setSelectedDivisionId(divisionId);
  };

  return (
    <Container id="Home">
      <Heading image={AboutUsImage}>
        <HeadingContent>
          <StyledHeading light>Jobs</StyledHeading>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          {!jobs && <p>Loading...</p>}
          {jobs && !summary && (
            <>
              <DivisionsContainer>
                <h2>Divisions</h2>
                {
                  <ul>
                    {divisions &&
                      divisions.map((division) => (
                        <DivisionItem
                          selected={selectedDivisionId === division.id}
                          onClick={() => handleDivisionChange(division.id)}
                          key={division.id}>
                          {division.name}
                        </DivisionItem>
                      ))}
                  </ul>
                }
              </DivisionsContainer>
              <DivisionsMobileContainer>
                <div>Divisions</div>
                <select
                  value={selectedDivisionId}
                  onChange={(e) => handleDivisionChange(e.target.value)}>
                  {divisions &&
                    divisions.map((division) => (
                      <option key={division.id} value={division.id}>
                        {division.name}
                      </option>
                    ))}
                </select>
              </DivisionsMobileContainer>
              <JobsContainer>
                {" "}
                {jobs.map((job) => (
                  <JobCard key={job.id} onClick={() => navigate(job.id)}>
                    <JobTitle title={job.title}>{job.title}</JobTitle>
                    <JobSalary>
                      <strong>Salary:</strong> {job.salary}
                    </JobSalary>
                    <JobFooter>
                      <JobLocation>{job.location}</JobLocation>
                      <JobLinkContainer>
                        <JobLink>&gt;</JobLink>
                      </JobLinkContainer>
                    </JobFooter>
                  </JobCard>
                ))}
              </JobsContainer>
            </>
          )}

          {jobs && summary && (
            <SummaryTable>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Consultant</th>
                </tr>
              </thead>
              <tbody>
                {jobs
                  .sort((a, b) => {
                    if (a.consultant.name < b.consultant.name) {
                      return -1;
                    }

                    if (a.consultant.name > b.consultant.name) {
                      return 1;
                    }

                    if (
                      a.consultant.name === b.consultant.name &&
                      a.consultant.surname < b.consultant.surname
                    ) {
                      return -1;
                    }

                    if (
                      a.consultant.name === b.consultant.name &&
                      a.consultant.surname > b.consultant.surname
                    ) {
                      return 1;
                    }

                    return 0;
                  })
                  .map((job) => (
                    <tr key={job.id}>
                      <td>{job.title}</td>
                      <td>
                        {job.consultant.name} {job.consultant.surname}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </SummaryTable>
          )}
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default Jobs;
