import AboutUsImage from "../../assets/about-us.jpg";
import { StyledHeading } from "../../components";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  height: 20vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--black);
  min-height: 80vh;
`;

const ContentWrapper = styled.div`
  padding: 2rem 3rem;

  a {
    color: var(--black);
  }

  a:visited {
    color: car(--black);
  }
`;

const EmailDisclaimer = () => (
  <Container>
    <Heading image={AboutUsImage}>
      <HeadingContent>
        <StyledHeading light>Confidentiality</StyledHeading>
      </HeadingContent>
    </Heading>
    <ContentContainer>
      <ContentWrapper>
        <p>
          Any email, sent from a sender within The Talent Experts (Pty) Ltd to
          you on any date, is confidential and may contain privileged or
          copyright information. You may not present this message to another
          party without consent from the sender. If you are not the intended
          recipient please notify the sender and delete this email. You are
          notified that disclosing, copying, distributing or taking any action
          in reliance on the contents of this information is strictly
          prohibited.
        </p>
        <h2>Liability</h2>
        <p>
          An email is not a binding agreement and does not conclude an agreement
          without the express confirmation by the sender’s superior or a
          director of the Company.
        </p>
        <h2>Viruses</h2>
        <p>We do not certify that any email is free of viruses or defects.</p>
        <h2>Requested</h2>
        <p>
          We do not consent to our employees sending un-asked for emails which
          contravene the law. In the event that you feel an email is such,
          please notify us in order for the appropriate corrective action to be
          taken.
        </p>
        <h2>Advice</h2>
        <p>
          Any views or opinions presented in an email are solely those of the
          author and do not necessarily represent those of the company. Any
          actions taken on the basis of this email are at the reader’s own risk.
        </p>
      </ContentWrapper>
    </ContentContainer>
  </Container>
);

export default EmailDisclaimer;
