import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 10vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--black);
  min-height: 90vh;
`;

const ContentWrapper = styled.div`
  padding: 2rem 3rem;

  a {
    color: var(--black);
  }

  a:visited {
    color: car(--black);
  }

  strong {
    font-size: 1.2rem;
  }

  em {
    font-size: 2rem;
    color: var(--white);
    text-shadow: 0 0 2px #ccc;
  }
`;

export { Container, Heading, HeadingContent, ContentContainer, ContentWrapper };
