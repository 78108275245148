import {
  AboutUs,
  ContactUs,
  Home,
  OurOffering,
  OurProcessAndAgreement,
  MeetTheDirectors,
} from "..";

const Static = () => {
  return (
    <>
      <Home />
      <AboutUs />
      <OurOffering />
      <OurProcessAndAgreement />
      <MeetTheDirectors />
      <ContactUs />
    </>
  );
};

export default Static;
