import "normalize.css";
import "./App.css";

import {
  ApplicantConsent,
  EmailDisclaimer,
  Job,
  Jobs,
  PrivacyPolicy,
  Static,
} from "./pages";
import {
  BackToTop,
  Container,
  ContentWrapper,
  MenuWrapper,
  MobileMenu,
} from "./App.styles";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { createRef, useEffect, useState } from "react";

import { Menu } from "./components";
import MenuIcon from "./assets/menu.svg";

const App = () => {
  const titlePrefix = "The Talent Experts";
  const [isTop, setIsTop] = useState(true);
  const [page, setPage] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [mobileMenuStatus, setMobileMenuStatus] = useState(false);
  const ScrollContainer = createRef();

  const handleClick = (anchorId) => {
    const anchor = document.querySelector(anchorId);

    if (anchor) {
      if (anchorId === "#Home") {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (ScrollContainer.current) {
        if (!isTop && ScrollContainer.current.scrollTop <= 400) {
          setIsTop(true);
        } else if (isTop && ScrollContainer.current.scrollTop > 400) {
          setIsTop(false);
        }
      }
    };
    ScrollContainer.current.addEventListener("scroll", checkScrollTop);
  }, [ScrollContainer, isTop]);

  useEffect(() => {
    if (page) {
      handleClick(`#${page}`);
      setPage(null);
      // Change Page Title here based on page
      setPageTitle(null);
    }
  }, [page]);

  useEffect(() => {
    document.title = pageTitle ? `${pageTitle} - ${titlePrefix}` : titlePrefix;
  }, [pageTitle]);

  return (
    <Router>
      <Container>
        {!isTop && !mobileMenuStatus && (
          <BackToTop onClick={() => handleClick("#Home")}>
            <div className="icon">&#9650;</div>
          </BackToTop>
        )}

        <MobileMenu onClick={() => setMobileMenuStatus(!mobileMenuStatus)}>
          <img src={MenuIcon} alt="menu" />
        </MobileMenu>

        <ContentWrapper ref={ScrollContainer}>
          <Switch>
            <Route path="/privacy-policy">
              <PrivacyPolicy scroll={handleClick} setPageTitle={setPageTitle} />
            </Route>
            <Route path="/email-disclaimer">
              <EmailDisclaimer setPageTitle={setPageTitle} />
            </Route>
            <Route path="/applicant-consent">
              <ApplicantConsent setPageTitle={setPageTitle} />
            </Route>
            <Route path="/jobs/:role/:id">
              <Job setPageTitle={setPageTitle} />
            </Route>
            <Route path="/jobs/:id">
              <Job setPageTitle={setPageTitle} />
            </Route>
            <Route path="/jobs">
              <Jobs setPageTitle={setPageTitle} />
            </Route>

            <Route path="/">
              <Static setPageTitle={setPageTitle} page={page} />
            </Route>
          </Switch>
        </ContentWrapper>
        <MenuWrapper mobileMenuStatus={mobileMenuStatus}>
          <Menu
            setPage={setPage}
            setMobileMenuStatus={setMobileMenuStatus}
            mobileMenuStatus={mobileMenuStatus}
          />
        </MenuWrapper>
      </Container>
    </Router>
  );
};

export default App;
