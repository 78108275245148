import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  /* min-height: 50vh; */
  background-color: var(--yellow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;

  .title {
    font-size: 4rem;
    margin: 0;
  }

  .areas {
    /* margin-bottom: 5rem; */
  }
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
`;

const BannerContent = styled.div`
  padding: 3rem;
`;

export { Container, Heading, HeadingContent, Banner, BannerContent };
