import AboutUsImage from "../../assets/about-us.jpg";
import { StyledHeading } from "../../components";
import styled from "styled-components";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 10vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--black);
  min-height: 90vh;
`;

const ContentWrapper = styled.div`
  padding: 2rem 3rem;

  a {
    color: var(--black);
  }

  a:visited {
    color: var(--black);
  }
`;

const PrivacyPolicy = ({ scroll }) => {
  useEffect(() => {
    // Makes sure that the page is scrolled to the top
    // as this page is a long bastard
    scroll("#Home");
    // eslint-disable-next-line
  }, []);

  return (
    <Container id="Home">
      <Heading image={AboutUsImage}>
        <HeadingContent>
          <StyledHeading light>Privacy Policy</StyledHeading>
        </HeadingContent>
      </Heading>
      <ContentContainer>
        <ContentWrapper>
          <h2>General</h2>
          <p>
            This is the privacy policy of The Talent Experts, registration
            number – 2018/297733/07 This policy details how we collect, store,
            protect and use personal information – that is information that can
            be used to identify an individual or a company (juristic person) –
            in connection with the services we may offer through our
            www.thetalentexperts.co.za website (referred to as the “Site”) and
            through your dealings with us (referred to as the “Services”).
          </p>
          <h2>Audience</h2>
          <p>
            Personal information submitted to us by using this Site or our
            Services is governed by this Privacy Policy.
          </p>
          <p>
            <strong>As the Operator or Processor</strong>
          </p>
          <p>
            Where we have been contracted by another company, such as your
            employer or a company you are dealing with directly, please be aware
            that we are not the entity responsible for making decisions on your
            data, but instead are rendering services to the company that you are
            dealing with directly. Please refer to the Privacy Policy of the
            company you are dealing with in these cases.
          </p>
          <p>
            <strong>As the Controller or Responsible Party</strong>
          </p>
          <p>
            Where you are dealing with us directly by means of our Services or
            Site, this Privacy Policy applies. You are not required to provide
            us with any personal information when using the Site, unless you
            choose to engage with us in a manner that requires us to have your
            information (such as by subscribing to our newsletter or enquiring
            about our Services). Should you engage us for our Services, you will
            be required to provide further information as detailed in this
            Privacy Policy.
          </p>
          <h2>What is personal information?</h2>
          <p>Personal information includes:</p>
          <ul>
            <li>
              certain information that is collected automatically when you visit
              our website,
            </li>
            <li>certain information collected on engaging our Services;</li>
            <li>certain information collected on submission; and</li>
            <li>optional information that you provide to us voluntarily</li>
          </ul>
          <p>
            Additional information captured on the website that does not
            classify as personal information may also be collected and
            processed, including but not limited to:
          </p>
          <ul>
            <li>anonymised information,</li>
            <li>
              de-identified information that cannot be associated with an
              individual,
            </li>
            <li>statistical information,</li>
            <li>
              information that is public knowledge, which has been publicly and
              voluntarily disclosed.
            </li>
          </ul>
          <h2>Who is processing your data?</h2>
          <p>
            In the case that we are the Data Controller or Responsible Party,
            then data processing is processed by us, an entity incorporated
            within the laws of South Africa registration number 2018/297733/07
            with the following contact details:
          </p>

          <p>
            The Talent Experts
            <br /> 11 Imam Haron Road
            <br /> Claremont
            <br /> 7735
            <br /> General Email:{" "}
            <a href="mailto:info@thetalentexperts.co.za">
              info@thetalentexperts.co.za
            </a>
            <br /> Data Related Queries:{" "}
            <a href="mailto:privacy@thetalentexperts.co.za">
              privacy@thetalentexperts.co.za
            </a>
            <br />
          </p>
          <h2>What are we processing and why?</h2>
          <p>
            There are various ways in which we may process your data. This
            section details our purposes for processing, our legal basis for the
            processing, and our storage periods.
          </p>
          <p>
            In certain cases, we will process your data after{" "}
            <strong>obtaining your consent</strong>. You are free to withdraw
            your consent at any time. We may process your data with your consent
            for the following purposes:
          </p>
          <ul>
            <li>
              Electronic communications, such as subscribing to our mailing list
              or by contacting us via a form or email address upon the Site.
            </li>
            <li>
              Cookies, in order to grant you a personalised experience when
              dealing with us. You may decline the installation of cookies;
              however, this may affect the functionality of our website or
              prevent you from using the Site entirely. If you opt out of using
              cookies, we may store a single cookie in order to remember this
              preference.
            </li>
            <li>
              For website analytics, storing details such as your IP address,
              bounce rate, page time, cost per click, most visited pages, and
              device information. These tracking cookies may be declined at any
              time.
            </li>
            <li>
              For promotional campaigns, in which case additional information
              may be volunteered by you in order to participate in the promotion
              or to receive a delivery.
            </li>
          </ul>
          <p>
            We will process your data when we have to{" "}
            <strong>perform a contract</strong>; for as long as the contractual
            relationship is in place, and for one year following an unsuccessful
            placement or three years following a successful placement. In order
            to fulfil our obligations to you in terms of the Services you have
            engaged us for, we will need to process your data.
          </p>
          <p>
            We may also process your data in terms of{" "}
            <strong>legitimate interest</strong>, as long as the data being
            processed is strictly necessary, proportional, and does not infringe
            on your individual rights to privacy. Processing may take place in
            terms of the following necessary scenarios:
          </p>
          <ul>
            <li>
              To monitor and analyse how to improve our Service and/or our Site,
              as well as to keep the same secure and free from abuse. This may
              include communications such as surveys or direct electronic
              communications.
            </li>
            <li>
              To keep active communications with you while you are our
              contracted client. Certain communications are necessary during
              delivery of our Services, and we have a legitimate interest in
              keeping your information about our Services.
            </li>
          </ul>
          <h2>Where is your data processed?</h2>
          <p>
            In some cases, your information may be stored and processed outside
            of the country or region where it was originally collected in order
            to complete our obligations to you. In some of these countries, you
            may have fewer rights in respect of your information than you do in
            your country of residence.
          </p>
          <p>
            Your agreement to the terms of this Privacy Policy, followed by your
            submission of information in connection with our Services,
            represents your agreement to this cross-border transfer of personal
            data. Our primary region for storing and process data is Frankfurt
            (Germany) and South Africa. We may also store and process your date
            in other regions.
          </p>
          <p>
            We make use of a supplier in India for the purpose of typing your
            Curriculum Vitae in our standard The Talent Experts CV Format. This
            supplier is under contract and has signed a strict non-disclosure
            agreement with The Talent Experts including an obligation to adhere
            to the data protection and privacy policies of The Talent Experts.
          </p>
          <h2>Who else will have access to your personal information?</h2>
          <p>
            We may share your information with our service providers and
            processors in order to deliver the Services to you. These will often
            be providers that offer solutions for hosting, marketing and other
            business critical operations. In the case of third parties
            processing data, we require that they comply with our internal
            standards, policies, technical and organisational measures, ensuring
            that your data is protected and remains confidential. We will only
            share data in accordance with this Privacy Policy and with approved
            and contractually appointed third parties.
          </p>
          <p>
            When you authorise us to do so, we may also share your data with
            other companies so that they can process the data for other
            purposes, as explained in more detail when we request your prior
            consent. In addition, if you provide consent for the installation of
            cookies, your data may be processed by third parties. These cookies
            are subject to the third parties' respective Privacy Policies.
          </p>
          <p>
            Where necessary and compelled by authorities to do so, we may share
            your information with law enforcement and competent courts. We may
            also share your information where we have to take legal action to
            protect our, or third-party rights.
          </p>
          <p>
            We will not sell your information. Our contracts dictate that
            third-party service providers may not use your information for their
            own benefit or for any services other than those requested by us.
          </p>
          <h2>How are we processing your data?</h2>
          <p>
            We will only process personal data as a result of the provision of
            our Services, or in operating our Site. These instructions are
            included in our standard Terms and Conditions and engagement
            contracts. Should we have reason to believe that your instructions
            infringe on data protection regulation, we will inform you promptly.
          </p>
          <p>
            We will ensure that our employees are subject to confidentiality
            agreements and any statutory privacy obligations.
          </p>
          <p>
            To provide you with the Services, we may need to use other service
            providers, as well as hire new providers in the future. Those
            companies will only process the data to the extent necessary to
            render the Services, and we will enter into written agreements with
            them to make sure that said companies comply with the obligations
            included in this Privacy Policy and implement all necessary security
            measures to ensure adequate protection of the data.
          </p>
          <p>
            In the event that we should change upstream service providers that
            are involved in providing you with Services, or where we may need to
            hire additional companies to provide the Services, you have the
            right to reasonably oppose such an appointment. Such opposition
            shall be addressed on a case-by-case basis in accordance with the
            appropriate data protection regulation.
          </p>
          <p>
            We will implement appropriate technical and organisational measures
            to ensure a level of security appropriate to the risk. At your
            request and expense and taking into account the nature of processing
            and the information available to us, we shall reasonably assist you
            in compliance with the security obligations set forth by data
            protection regulation.
          </p>
          <p>
            A list of our sub processors is available on request and is included
            in our Data Processing Agreements.
          </p>
          <p>
            Upon termination of your services, we will delete your personal
            data, unless otherwise required for a statutory requirement or as
            per the retention periods set out in this Privacy Policy.
          </p>
          <h2>What happens in the event of a breach?</h2>
          <p>
            In the event of a confirmed breach of security leading to the
            accidental or unlawful destruction, loss, alteration, unauthorised
            disclosure of, or access to, personal data, we shall promptly assess
            the risk to people’s rights and freedoms and without undue delay
            report this breach to the appropriate authorities, controllers,
            responsible parties, and subjects as required by law.
          </p>
          <p>
            We will cooperate with you and take such reasonable commercial steps
            as are directed by you to assist in the investigation, mitigation,
            and remediation of each such data breach.
          </p>
          <h2>Your rights</h2>
          <p>
            You have the right to request access to, and rectification or
            erasure of your personal information. You may also restrict process,
            or object to processing in accordance with relevant data protection
            regulation. In cases where consent was given, you have the right to
            withdraw consent at any time.
          </p>
          <p>
            If you do request that your information is deleted, all information
            will be permanently erased, except for information that we are
            required to keep by law. You may exercise your rights at any time by
            lodging a request with our Information Officer at{" "}
            <a href="mailto:t.faux@thetalentexperts.co.za">
              t.faux@thetalentexperts.co.za
            </a>
          </p>
          <h2>Updating your information</h2>
          <p>
            You may update your information in writing at any time by submitting
            a support request or sending an email to our Information Officer at{" "}
            <a href="mailto:t.faux@thetalentexperts.co.za">
              t.faux@thetalentexperts.co.za
            </a>
            . Please remember that it is your duty to keep information updated
            so we can correctly provide you with the Services, and you undertake
            to verify the information you have handed us from time to time to
            make sure that it is accurate.
          </p>
          <h2>Changes to this policy</h2>
          <p>
            This policy may be updated from time to time. You may be required to
            accept the update Privacy Policy in order to keep using our
            Services. Alternatively, we may post non-material changes to this
            Privacy Policy on our Site, with a notice advising of the effective
            date of the changes. We may notify you of material changes to the
            Privacy Policy by email (or otherwise) prior to the effective date
            of the changes.
          </p>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default PrivacyPolicy;
