import { Container, Heading, HeadingContent, Banner } from "./home.styles";
import { StyledHeading } from "../../components";
import Logo from "../../assets/The_Talent_Experts_black.svg";

const Home = () => {
  return (
    <>
      <Container id="Home">
        <Heading>
          <HeadingContent>
            <Banner>
              <div>
                <img src={Logo} alt="The Talent Experts" width="150px" />
              </div>
              <div>
                <StyledHeading light size={"2rem"}>
                  Recruitment Experts with specialists across four niche areas
                </StyledHeading>
                <p>
                  <strong>
                    Engineering &bull; Financial Markets &bull; Commercial &amp;
                    Financial &bull; Information Technology
                  </strong>
                </p>
              </div>
            </Banner>
          </HeadingContent>
        </Heading>
      </Container>
    </>
  );
};

export default Home;
