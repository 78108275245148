import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  width: var(--menuWidth);
`;

const CloseIconContainer = styled.div`
  padding: 1rem;

  img {
    cursor: pointer;
  }
`;

const MenuContainer = styled.div`
  padding: 1rem 3rem 0 3rem;
`;

const MenuEntry = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
`;

const RoutedMenuEntry = styled(Link)`
  text-decoration: none;
  color: var(--black);
  font-size: 1.2rem;
`;

const PhoneEntry = styled.a`
  color: var(--black);
  text-decoration: none;
  font-size: 1.2rem;
`;

const APSOLogoContainer = styled.div`
  padding: 2rem 3rem;
  display: flex;
  justify-content: flex-end;

  img {
    width: 120px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;

  img {
    cursor: pointer;
    width: 200px;
  }
`;

const StyledLink = styled(Link)`
  color: var(--black);
  text-decoration: none;
  font-size: 0.8rem;
`;

export {
  Container,
  MenuEntry,
  MenuContainer,
  PhoneEntry,
  APSOLogoContainer,
  LogoContainer,
  StyledLink,
  RoutedMenuEntry,
  CloseIconContainer,
};
