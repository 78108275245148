import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--yellow);
`;

const Heading = styled.div`
  height: 20vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--black);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  p {
    text-align: center;
  }
`;

const JobsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  flex-grow: 1;
  justify-content: center;
  a {
    color: var(--black);
  }

  a:visited {
    color: car(--black);
  }
`;

const DivisionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  min-width: 18rem;
  flex-direction: column;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const DivisionsMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  flex-direction: column;

  @media (min-width: 850px) {
    display: none;
  }
`;

const DivisionItem = styled.li`
  margin: 0.5rem 0;
  cursor: pointer;
  user-select: none;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
`;

const JobCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 250px;
  height: 100px;
  background-color: var(--white);
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.5s;
  border-radius: 5px;
  user-select: none;

  @media (max-width: 1024px) {
    max-width: 250px;
    min-width: 250px;
  }

  @media (max-width: 768px) {
    min-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    box-shadow: 0 10px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
`;

const JobTitle = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  @media (max-width: 1024px) {
    max-width: 250px;
    min-width: 250px;
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const JobSalary = styled.div`
  font-size: 0.8rem;
  strong {
    font-size: 0.8rem;
  }
`;

const JobFooter = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`;

const JobLocation = styled.div`
  width: 80%;
  color: #bbb;
`;

const JobLinkContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;

const JobLink = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  background-color: var(--white);

  & thead {
    background-color: #f2f2f2;
  }

  & th,
  & td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  & tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export {
  Container,
  ContentContainer,
  ContentWrapper,
  DivisionItem,
  DivisionsContainer,
  DivisionsMobileContainer,
  Heading,
  HeadingContent,
  JobCard,
  JobFooter,
  JobLink,
  JobLinkContainer,
  JobLocation,
  JobSalary,
  JobsContainer,
  JobTitle,
  SummaryTable,
};
