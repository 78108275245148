import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.8rem;
  background-color: ${(props) =>
    props.color === "white" ? `var(--white)` : `var(--yellow)`};
  color: var(--black) !important;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.5s;
  border-radius: 5px;
  user-select: none;

  &:hover {
    box-shadow: 0 6px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
`;

const LinkButton = ({ label, path, color }) => (
  <Button to={path}>{label}</Button>
);

export default LinkButton;
