import OurProcessAndAgreementImage from "../../assets/our-values.jpg";
import { StyledHeading } from "../../components";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 30vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 20vh;
  }
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  min-height: 70vh;
  background-color: var(--yellow);
  color: var(--black);
`;

const ContentWrapper = styled.div`
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  @media (max-width: 768px) {
    min-height: 30vh;
  }
`;

const Content = styled.div``;

const TableContainer = styled.div`
  display: flex;
  padding: 1rem 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TableCard = styled.div`
  width: 50%;
  margin-left: 3rem;
  background: var(--white);
  color: var(--black);
  border-radius: 5px;
  padding: 1rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0;
  }
`;

const OurProcessAndAgreement = () => {
  return (
    <Container id="OurProcessAndAgreement">
      <Heading image={OurProcessAndAgreementImage}>
        <HeadingContent>
          <StyledHeading light>Our Process &amp; Agreement</StyledHeading>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          <Content>
            <h2>Our Process</h2>
            <TableContainer>
              <TableCard>
                <h3>Clients</h3>
                <p>
                  In order to secure you the finest talent we follow our proven
                  process:
                </p>
                <ul>
                  <li>
                    You brief us on your vacancy - We use our in-depth sourcing
                    techniques to present you the top 3 candidates for your role
                    within 10 working days
                  </li>
                  <li>
                    We guide both you and the candidate through the recruitment
                    process to avoid any nasty surprises
                  </li>
                  <li>
                    We manage the presentation of the offer, possible
                    negotiation, acceptance, as well as onboarding
                  </li>
                  <li>
                    We keep in touch with both you and the candidate for the
                    first 3 months to make sure the transition runs smoothly
                  </li>
                </ul>
              </TableCard>
              <TableCard>
                <h3>Candidates</h3>
                <p>
                  In order to secure you the best job we follow our proven
                  process:
                </p>
                <ul>
                  <li>
                    We do an in-depth interview where we get to know you and
                    your career history and aspirations making sure that we can
                    match you to the right role
                  </li>
                  <li>
                    We make sure that your CV puts your experience forward in
                    the best light
                  </li>
                  <li>We help you prepare for the interviews</li>
                  <li>We help you negotiate the best offer</li>
                  <li>
                    We understand that making a career decision is emotionally
                    difficult and we guide you through process
                  </li>
                  <li>We assist with your resignation and your onboarding</li>
                  <li>
                    We stay in touch even after you've started to make sure that
                    you transition well
                  </li>
                </ul>
              </TableCard>
            </TableContainer>

            <h2>Our Agreement</h2>
            <TableContainer>
              <TableCard>
                <h3>Clients</h3>

                <ul>
                  <li>
                    Our service is backed by a 3 month guarantee which means
                    that we will replace free of charge should anything go wrong
                  </li>
                  <li>
                    Each candidate that we present will have gone through a full
                    min 45 - 60 minutes video interview with one of our
                    specialist consultants
                  </li>
                  <li>
                    We take an in-depth min 2 page reference on each candidate
                    before the offer is extended
                  </li>
                  <li>
                    We utilise the latest technology - we can send you a digital
                    interview along with the CV to give you a feel of the
                    candidate before committing time to an interview
                  </li>
                </ul>
              </TableCard>

              <TableCard>
                <h3>Candidates</h3>
                <ul>
                  <li>
                    We will only present your details to a company after
                    receiving your permission
                  </li>
                  <li>We guarantee you confidentiality</li>
                  <li>
                    You will receive weekly feedback throughout the process
                  </li>
                  <li>
                    We will stay in touch with you 3 months after you've started
                    to make sure you have transitioned well
                  </li>
                  <li>
                    We promise to have your best interest at heart and will
                    never push you into making a decision
                  </li>
                </ul>
              </TableCard>
            </TableContainer>
          </Content>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default OurProcessAndAgreement;
