import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  position: relative;
  z
`;

const ContentWrapper = styled.div`
  background-color: var(--yellow);
  overflow-y: auto;
  --ms-overflow-style: none;
  scrollbar-width: none;
  min-width: calc(100vw - var(--menuWidth));

  @media (max-width: 768px) {
    min-width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  background-color: var(--white);
  width: var(--menuWidth);
  height: 100%;

  @media (max-width: 768px) {
    display: ${({ mobileMenuStatus }) => (mobileMenuStatus ? "flex" : "none")};
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const MobileMenu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const BackToTop = styled.div`
  position: absolute;
  z-index: 999;
  bottom: 5vh;
  right: 225px;
  background-color: var(--yellow);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 9px -3px rgba(0, 0, 0, 1);
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;

  @media (max-width: 768px) {
    bottom: 5vh;
    right: 10vw;
  }

  &:hover {
    opacity: 1;
  }

  .icon {
    padding-top: 5px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

export { Container, ContentWrapper, MenuWrapper, MobileMenu, BackToTop };
