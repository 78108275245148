import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 30vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
`;

const HeadingContent = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;

  @media (max-width: 768px) {
    div {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  min-height: 70vh;
  background-color: var(--black);
  color: var(--white);
`;

const ContentWrapper = styled.div`
  padding: 3rem;

  a {
    color: var(--white);
  }

  a:visited {
    color: car(--white);
  }
`;

const AddressWrapper = styled.div`
  margin: 6rem 12rem;
  display: flex;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Address = styled.div`
  border-left: 3px solid var(--yellow);
  padding-left: 2rem;
`;

export {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  AddressWrapper,
  Address,
};
