import styled from "styled-components";

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 1rem;
  box-shadow: 4px 4px 9px -3px rgba(0, 0, 0, 1);
  border-radius: 5px;
  background-color: ${(props) =>
    props.primary ? "var(--yellow)" : "var(--white)"};
`;

export default StyledButton;
