import {
  Container,
  Content,
  ContentContainer,
  ContentWrapper,
  Heading,
  HeadingContent,
} from "./about-us.styles";

import AboutUsImage from "../../assets/about-us.jpg";
import { StyledHeading } from "../../components";
import Pdf from "../../assets/The-Talent-Experts-B-BBEE-certificate.pdf";

const AboutUs = () => {
  return (
    <Container id="AboutUs">
      <Heading image={AboutUsImage}>
        <HeadingContent>
          <StyledHeading light>About Us</StyledHeading>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          <Content>
            <p>
              With more than 100 years’ combined experience in the recruitment
              industry, we’re a specialist permanent recruitment consultancy
              working across industry; with a focus on finding elusive,
              scarce-skill business talent in complex and niche sectors.
            </p>
            <p>
              We aim to create quality relationships between ourselves, our
              clients, and ultimately between long-term employee and employer
              partners. In doing so our goal is to help people achieve their
              ambitions, help businesses flourish, and improve the world we live
              in through positive and passionate connections.
            </p>
            <p>
              Level 4 B-BBEE supplier, for more information please{" "}
              <a href={Pdf} target="_blank">
                click here
              </a>
              .
            </p>
            <StyledHeading light>Your success is our business.</StyledHeading>
            <StyledHeading light size="2rem">
              We will always ensure our service to you is at the highest
              standard.
            </StyledHeading>
            <h3>Values</h3>
            <p>Quality, Positivity, Accountability, Empathy</p>
            <h3>Our Brand Promise</h3>
            <p>
              Connecting quality talent to exceptional opportunities whilst
              building lasting relationships
            </p>
            <h3>Vision</h3>
            <p>
              We believe passionately that we have the possibility to make the
              world a better place with every connection that we create.
            </p>
            <h3>Mission</h3>
            <p>To connect top talent with the best employers.</p>
          </Content>
          {/* <Image image={AboutUsImage} /> */}
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default AboutUs;
