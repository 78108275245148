import styled from "styled-components";

const Card = styled.div`
  width: 300px;
  background-color: var(--white);
  color: var(--black);
  border-radius: 5px;
  overflow: hidden;
  margin: 2rem;
  transition: all 0.5s;
  &:hover {
    box-shadow: 0 10px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.5rem;
  }
`;

const CardHeader = styled.div`
  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  height: 10rem;
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const Container = styled.div`
  display: flex;
`;

const DirectorCard = ({ image, heading, content }) => (
  <Container>
    <Card>
      <CardHeader image={image} />
      <CardContent>
        <h3>{heading}</h3>

        <p>{content}</p>
      </CardContent>
    </Card>
  </Container>
);

export default DirectorCard;
