import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 30vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 20vh;
  }
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  min-height: 70vh;
  background-color: var(--yellow);
  color: var(--black);
`;

const ContentWrapper = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  @media (max-width: 768px) {
    min-height: 30vh;
  }
`;

const Content = styled.div`
  height: 30%;
  p {
    font-size: 1.2rem;
  }
`;

const Values = styled.div`
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  Content,
  Values,
};
