import OurOfferingImage from "../../assets/our-offering.jpg";
import ContactUsImage from "../../assets/contact-us.jpg";
import AboutUsImage from "../../assets/about-us.jpg";
import HomeImage from "../../assets/home.jpg";
import OurValuesImage from "../../assets/our-values.jpg";

import { StyledHeading, EmailButton, OfferingCard } from "../../components";
import {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  OfferingCardsContainer,
} from "./our-offering.styles";

const OurOffering = () => {
  return (
    <Container id="OurOffering">
      <Heading image={OurOfferingImage}>
        <HeadingContent>
          <StyledHeading light>Our Offering</StyledHeading>
          <div>
            <EmailButton
              label="Looking for the best and brightest?"
              mailto="mailto:info@thetalentexperts.co.za?subject=Job%20specification&body=Hi%20Talent%20Experts!%0A%0APlease%20find%20attached%20a%20job%20specification%0A%0A-----%0ADon%27t%20forget%20to%20attach%20the%20specification%20and%20feel%20free%20to%20add%20any%20important%20details%20you%20require%20in%20the%20message"
            />
          </div>
        </HeadingContent>
      </Heading>

      <ContentContainer>
        <ContentWrapper>
          <p>
            Our clients range from strong, owner-managed small to medium sized
            businesses to large listed corporations. Our current focus in the
            Engineering, Financial Markers and Commercial/Financial space is on
            South Africa with capability across the rest of the continent. Our
            Information Technology team has a key focus across Africa.
          </p>
          <p>
            Your privacy is of huge importance to us, all information supplied
            is strictly confidential and protected according to specialist
            recruitment best practices.
          </p>

          <p>
            We focus on mid- to senior level roles in the commercial, financial
            and engineering space, including but not limited to:
          </p>

          <OfferingCardsContainer>
            <OfferingCard
              image={OurOfferingImage}
              heading="Human Resources"
              content="Specialists in the area of employee well-being. This
                    includes payroll, benefits, leave, formulation of job
                    descriptions, performance reviews, dispute management and
                    keeping up to date with relevant legislation."
            />

            <OfferingCard
              image={ContactUsImage}
              heading="Sales"
              content="Energetic, sociable, solution-driven individuals who excel
                    at customer/client service, building relationships, creating
                    and converting leads and delivering on targets."
            />

            <OfferingCard
              image={AboutUsImage}
              heading="Logistics &amp; Supply Chain"
              content="Detail-oriented individuals with a keen eye for coordination
                    and tracking. Key responsibilities include management of
                    storage, transportation, delivery and the overall efficient
                    maintenance of stock."
            />

            <OfferingCard
              image={HomeImage}
              heading="Finance"
              content="Numerate and analytical, these specialists possess skills
                    ranging from strategy and planning to auditing and reporting
                    as well as hands-on accounting."
            />

            <OfferingCard
              image={OurValuesImage}
              heading="Legal"
              content="Focused professionals across a wide range of legal roles in
                    corporate, including company secretarial, legal advisors and
                    compliance officers/managers."
            />

            <OfferingCard
              image={OurOfferingImage}
              heading="Engineering"
              content="Technically-minded, solution-driven individuals, skilled at the
            scientific development of practical solutions to meet societal and
            consumer needs."
            />

            <OfferingCard
              image={ContactUsImage}
              heading="Financial Services"
              content="We specialize in the recruitment of the top investment management,
            operations, private equity, risk management and quantitative
            analytics professionals."
            />

            <OfferingCard
              image={AboutUsImage}
              heading="Information Technology"
              content="Focussed on finding Leadership talent in the Digital Transformation,
            Technology and Product areas within the Payments, E-commerce and
            FinTech niche's across the African continent."
            />
          </OfferingCardsContainer>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  );
};

export default OurOffering;
