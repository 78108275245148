import AboutUsImage from "../../assets/about-us.jpg";
import { StyledHeading } from "../../components";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  height: 20vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
`;

const ContentContainer = styled.div`
  background-color: var(--yellow);
  color: var(--black);
  min-height: 80vh;
`;

const ContentWrapper = styled.div`
  padding: 2rem 3rem;

  a {
    color: var(--black);
  }

  a:visited {
    color: car(--black);
  }
`;
const ApplicantConsent = () => (
  <Container>
    <Heading image={AboutUsImage}>
      <HeadingContent>
        <StyledHeading light>Applicant Consent</StyledHeading>
      </HeadingContent>
    </Heading>
    <ContentContainer>
      <ContentWrapper>
        <p>
          I agree to the processing of my personal data included in this
          application. I consent to the processing and retention of the personal
          information contained in my curriculum vitae or resume for the
          purposes of placements in future roles that become available.
        </p>
        <p>
          I am aware that I have the right to withdraw consent at any time, and
          that I have agreed to the{" "}
          <a target="_blank" href="./privacy-policy">
            Privacy Policy
          </a>
          . I agree to my information being shared with potential employers for
          the purposes of applications to available roles after discussions with
          a member of The Talent Experts.
        </p>
      </ContentWrapper>
    </ContentContainer>
  </Container>
);

export default ApplicantConsent;
