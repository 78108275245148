import styled from "styled-components";

const StyledHeading = styled.h1`
  font-size: ${({ size }) => (size ? size : "3rem")};
  font-weight: 900;
  font-style: italic;
  color: ${(props) =>
    props.light
      ? "var(--white)"
      : props.yellow
      ? "var(--yellow)"
      : "var(--black)"};
  text-shadow: ${(props) => (props.light ? "0 0 2px #000" : "none")};
`;

export default StyledHeading;
