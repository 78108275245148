import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  min-height: 10vh;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-color: var(--black);
`;

const HeadingContent = styled.div`
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    div {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  min-height: 90vh;
  background-color: var(--yellow);
  color: var(--black);
`;

const ContentWrapper = styled.div`
  padding: 3rem;

  a {
    color: var(--black);
  }

  a:visited {
    color: var(--black);
  }
`;

const OfferingCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export {
  Container,
  Heading,
  HeadingContent,
  ContentContainer,
  ContentWrapper,
  OfferingCardsContainer,
};
