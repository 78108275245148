import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  color: var(--black);
  border-radius: 5px;
  // overflow: hidden;
  margin: 2rem;

  box-shadow: 0 10px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const CardHeader = styled.div`
  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  min-width: 250px;
  max-width: 250px;

  @media (max-width: 1200px) {
    min-width: 100%;
    min-height: 750px;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const CardContent = styled.div`
  padding: 1rem;
  flex: 1;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  // display: flex;
`;

const OfferingCard = ({ image, heading, content }) => (
  <Container>
    <Card>
      <CardHeader image={image} />
      <CardContent>
        <h2>{heading}</h2>

        <p dangerouslySetInnerHTML={{ __html: content }} />
      </CardContent>
    </Card>
  </Container>
);

export default OfferingCard;
