import styled from "styled-components";

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.8rem;
  background-color: var(--white);
  color: var(--black);
  width: fit-content;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.5s;
  border-radius: 5px;
  user-select: none;

  &:hover {
    box-shadow: 0 6px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
`;

const EmailButton = ({ label, mailto }) => {
  return (
    <Button
      onClick={() => {
        window.location = mailto;
      }}
    >
      {label}
    </Button>
  );
};

export default EmailButton;
